<template>
  <CommonTooltip
    :tooltip="shouldDisplayTooltip ? name : undefined"
    :as="ElementsButton"
    class="flex h-10 w-full"
    type="block"
    @mouseover="checkShouldDisplayTooltip"
  >
    <span ref="spanNode" class="line-clamp-1 text-sm">{{ name }}</span>
  </CommonTooltip>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import ElementsButton from '@autobid/ui/components/elements/button/Button.vue'

type Props = {
  name: string
}

defineProps<Props>()

const shouldDisplayTooltip = ref(false)
const spanNode = ref<HTMLSpanElement>()

const checkShouldDisplayTooltip = () => {
  if (!spanNode.value) return

  shouldDisplayTooltip.value = spanNode.value.scrollHeight > spanNode.value.clientHeight
}
</script>
