<template>
  <div class="flex h-full w-full flex-col gap-2 border border-primary p-4">
    <div class="flex flex-wrap justify-between gap-1.5">
      <div
        class="text-lg font-semibold uppercase"
        :class="getTextColor(state, car.stage, 'state', car.price.current)"
      >
        {{ $t(stateText) }}
      </div>

      <ElementsCarFreeReservationButton
        :car="car"
        class="[&_button]!px-4 [&_button]:text-xs"
      />
    </div>
    <div class="flex w-full justify-between">
      <div class="flex flex-col">
        <div class="text-gray-500">
          <div>{{ $t('barometer.call-price') }}</div>
          <div>
            <CalculatedPrice class="font-semibold" :price="car.price.start" />
          </div>
          <div class="text-xs underline">
            <ElementsCarVatLink
              :car="car"
              :tax-popup-slug="taxPopupSlug"
              class="inline-block self-start text-xs underline"
            />
          </div>
        </div>
      </div>
      <div class="flex flex-col">
        <div class="text-2xl">{{ $t('barometer.current-bid') }}:</div>
        <div class="text-2xl">
          <transition
            enter-active-class="transition duration-300"
            enter-from-class="scale-125 text-primary"
            enter-to-class="scale-100 text-inherit"
            leave-active-class="transition duration-300"
            leave-from-class="scale-100 text-inherit"
            leave-to-class="scale-125 text-primary"
            mode="out-in"
          >
            <CommonCalculatedPrice
              v-if="isAuthed"
              :key="car.price.current"
              class="font-semibold"
              :class="
                getTextColor(state, car.stage, 'price', car.price.current)
              "
              :price="car.price.current"
            />
            <span v-else :key="1" class="text-lg">{{
              $t('shared-car.confidential')
            }}</span>
          </transition>
        </div>

        <SectionsBarometerBidAgentInfo
          v-if="isAuthed && car.stage !== 'FINISHED'"
          :car="car"
        />
      </div>
    </div>
    <div
      v-if="car.isHighestBidder && isAuthed"
      class="mt-auto flex justify-end gap-2 uppercase"
      :class="{
        [COLORS.darkOrange.text]: state.isUnderAuctionReservation,
        [COLORS.green.text]: state.isSold,
        'text-primaryDark': !state.isUnderAuctionReservation && !state.isSold
      }"
    >
      <span class="mt-auto justify-end">{{
        $t('barometer.you-are-the-highest-bidder')
      }}</span>
      <div
        class="mt-auto flex h-8 w-8 items-center justify-center rounded-full border-2 border-primary text-lg"
      >
        <Icon
          mode="svg"
          name="akar-icons:arrow-up"
          size="32"
          class="text-primary"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import CalculatedPrice from '@autobid/ui/components/common/CalculatedPrice.vue'
import type { BarometerCar } from '@autobid/ui/types/Car'
import type { AuctionState } from '@autobid/ui/types/AuctionState'
import { COLORS, getTextColor } from '@autobid/ui/utils/car/getColor'
import { useAutobidAuth } from '@autobid/nuxt-auth/src/composables/useAutobidAuth'
import { formatPrice } from '@autobid/ui/utils/formatPrice'

type Props = {
  car: BarometerCar
  taxPopupSlug: string
  stateText?: string
}

const state: AuctionState = inject('state')
defineProps<Props>()

const { isAuthed } = useAutobidAuth()
</script>
