<template>
  <div class="mobile-bid flex w-full flex-col p-2">
    <div v-if="!isAuthed">
      <i18n-t keypath="barometer.bid-action-unauthenticated.text" tag="p">
        <template #registerLink>
          <common-link :href="registerUrl" class="underline">
            {{
              $t('barometer.bid-action-unauthenticated.register-button-text')
            }}
          </common-link>
        </template>

        <template #loginLink>
          <a role="button" class="underline" @click="signIn">
            {{ $t('barometer.bid-action-unauthenticated.login-button-text') }}
          </a>
        </template>
      </i18n-t>
    </div>

    <div
      v-else-if="doesAuctionMeetConditions && !specialConditionAccepted"
      class="py-2"
    >
      <p>{{ $t('auction-special-condition.barometer-description') }}</p>

      <elements-button
        class="mt-2"
        @click="showSpecialConditionNotificationDialog = true"
      >
        {{ $t('auction-special-condition.barometer-show-terms') }}
      </elements-button>
    </div>

    <template v-else-if="car.stage !== 'FINISHED'">
      <ElementsCarQuickBid :car="car" @handle-bid="handleBid" />
      <FormKit
        :id="BID_FORM_KEY_MOBILE"
        v-slot="{ disabled }"
        type="form"
        :actions="false"
        novalidate
        @submit="
          handleSubmit({
            currentType: 1,
            currentPrice: car.price.current ?? 100,
            currentId: car.id
          })
        "
      >
        <div class="relative flex w-full flex-col items-center">
          <FormKit
            ref="inputRef"
            :floating-label="true"
            :disabled="!isUserPermitted"
            type="number"
            :label="$t('barometer.your-bid')"
            name="bid"
            :step="inputBidStep"
            :min="getMinimalInputPrice(car, inputBidStep)"
            :validation="`number|min:${getMinimalInputPrice(car, inputBidStep)}
              |bidValidation:${inputBidStep},${getMinimalInputPrice(
              car,
              inputBidStep
            )}`"
            :validation-rules="{
              bidValidation: (node) => inputBidValidation(node, car)
            }"
            validation-visibility="submit"
            :validation-messages="{
              min: minPriceValidationMessage,
              bidValidation: bidStepPriceValidationMessage
            }"
          />
          <FormKitMessages class="mr-auto text-left" :node="inputRef?.node" />
          <button
            :disabled="!isUserPermitted || Boolean(disabled)"
            class="absolute right-0 top-6 h-11 w-32 bg-[#4BA224] px-4 py-2 text-2xl text-white"
            type="submit"
          >
            <i
              class="ab-icon ab-icon-hammer mx-auto -mt-2 h-full scale-150"
            ></i>
          </button>
        </div>
      </FormKit>
      <FormKit
        :id="BID_AGENT_FORM_KEY_MOBILE"
        v-slot="{ disabled }"
        type="form"
        :actions="false"
        novalidate
        @submit="
          handleSubmit({
            currentType: 2,
            currentPrice: car.price.current ?? 100,
            currentId: car.id
          })
        "
      >
        <div class="relative flex w-full flex-col items-center">
          <FormKit
            ref="agentInputRef"
            :floating-label="true"
            :disabled="!isUserPermitted"
            type="number"
            :label="
              car.ownBidAgentAmount > car.price.current
                ? $t('barometer.change-bid-agent')
                : $t('barometer.hire-bid-agent')
            "
            name="bid-agent"
            :step="inputBidStep"
            :min="getMinimalInputPrice(car, inputBidStep)"
            :validation="`number|min:${getMinimalInputPrice(car, inputBidStep)}
              |bidValidation:${inputBidStep},${getMinimalInputPrice(
              car,
              inputBidStep
            )}`"
            :validation-rules="{
              bidValidation: (node) => inputBidValidation(node, car)
            }"
            validation-visibility="submit"
            :validation-messages="{
              min: minPriceValidationMessage,
              bidValidation: bidStepPriceValidationMessage
            }"
          />
          <FormKitMessages
            class="mr-auto text-left"
            :node="agentInputRef?.node"
          />
          <button
            :disabled="!isUserPermitted || Boolean(disabled)"
            class="absolute right-0 top-6 h-11 w-32 bg-primary px-4 py-2 text-2xl text-white"
            type="submit"
          >
            <Support />
          </button>
        </div>
      </FormKit>
    </template>

    <b v-else-if="car.state">
      {{
        $t(`barometer.${car.state.toLowerCase()}-info`, {
          theHighestBidder: usersBids[0]?.user_nickname ?? '',
          buyer: usersBids[0]?.user_nickname ?? ''
        })
      }}
    </b>
  </div>
  <LazyCommonDialogBidConfirmation
    v-if="isBarometerMobile"
    :car="car"
    :bid="temporaryBid"
    :is-open="isConfirmationOpen"
    :type="type"
    :is-bidding="isBidding"
    @confirm="handleBidConfirmation"
    @close="handleBidCancel"
  />
</template>

<script setup lang="ts">
import type { BarometerCar } from '@autobid/ui/types/Car'
import { useBidAction } from '@autobid/ui/composables/car/useBidAction'
import Support from '@autobid/ui/components/common/icon/Support.vue'
import { useIsPermittedToBid } from '@autobid/ui/composables/car/useIsPermittedToBid'
import { FormKitMessages } from '@formkit/vue'
import {
  BID_AGENT_FORM_KEY_MOBILE,
  BID_FORM_KEY_MOBILE
} from '@autobid/ui/constants/BAROMETER_FORMS_IDS'
import { getMinimalInputPrice } from '@autobid/ui/utils/car/getMinimalInputPrice'
import { inputBidValidation } from '@autobid/ui/utils/car/bidValidation'
import { useAutobidAuth } from '@autobid/nuxt-auth/src/composables/useAutobidAuth'
import type { AuctionItemProps } from '@autobid/ui/types/components/AuctionItem'
import { computed } from 'vue'
import { useIsBarometerMobile } from '@autobid/ui/composables/car/useIsBarometerMobile'
import { getUsersBids } from '@autobid/ui/utils/car/getUsersBids'
import { useSpecialConditionNotification } from '@autobid/ui/composables/auction/useSpecialConditionNotification'

type Props = {
  car: BarometerCar
  isNotSold?: boolean
}

const props = defineProps<Props>()
const { registerUrl }: AuctionItemProps = inject('auction-item-props')
const isUserPermitted = useIsPermittedToBid()
const { isAuthed, signIn } = useAutobidAuth()
const {
  specialConditionAccepted,
  doesAuctionMeetConditions,
  showSpecialConditionNotificationDialog
} = useSpecialConditionNotification({ auctionId: props.car.auctionId })
const isBarometerMobile = useIsBarometerMobile()

const {
  handleBid,
  handleSubmit,
  inputRef,
  temporaryBid,
  isConfirmationOpen,
  handleBidConfirmation,
  handleBidCancel,
  agentInputRef,
  type,
  isBidding,
  minPriceValidationMessage,
  inputBidStep,
  bidStepPriceValidationMessage
} = useBidAction({
  bidOptions: computed(() => props.car.bidOptions)
})

const usersBids = computed(() => getUsersBids(props.car.bids))
</script>

<style lang="scss">
.mobile-bid {
  input[type='number'] {
    padding-right: 8.25rem; // button width plus padding
  }
}
</style>
