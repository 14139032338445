<template>
  <div class="flex min-h-[628px] w-full items-center border border-primary">
    <div v-if="!displayBids?.length" class="flex w-full flex-col">
      <div
        v-for="index in 20"
        :key="index"
        class="flex h-8 w-full animate-pulse bg-gray-200 text-left text-lg font-bold even:bg-gray-100"
      ></div>
    </div>
    <div v-else class="flex h-full w-full flex-col">
      <SingleBid
        v-for="(item, index) in displayBids"
        :key="item.amount"
        :index="index"
        :bid="item"
        :car="car"
        :price-already-changed="priceAlreadyChanged"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useDisplayingCarBids } from '@autobid/ui/composables/car/useDisplayingCarBids'
import { useCar } from '@autobid/ui/composables/useCar'
import { ref, watch } from 'vue'
import SingleBid from './SingleBid.vue'

type Props = {
  currency: string
  carId: number
}

const props = defineProps<Props>()

const { data: car } = useCar({ type: 'barometer', carId: props.carId })
const { displayBids } = useDisplayingCarBids(car)
const priceAlreadyChanged = ref(false)

watch(
  () => car.value.price.current,
  (_, oldVal) => {
    if (oldVal === undefined) return

    priceAlreadyChanged.value = true
  }
)
</script>
