<template>
  <div class="flex flex-1 text-gray-500 even:bg-gray-500/5">
    <div
      class="z-[-1] min-h-[32px] w-4"
      :class="leftTileClasses"
      :style="{
        opacity
      }"
    ></div>
    <div class="flex w-full items-center pl-4" :class="priceWrapperClasses">
      <div
        v-if="isAuthed"
        class="flex w-full items-center"
        :class="{
          'current-bid-animation':
            isAuthed && isCurrentBid && priceAlreadyChanged
        }"
      >
        <div
          class="flex"
          :class="{
            'text-success': bid.status === 0 && !isHighestBid
          }"
        >
          <div class="w-14 text-end text-lg">
            {{ formatPrice(bid.amount) }}
          </div>
          <div v-if="bid.user_nickname" class="ml-8 w-24 font-sans text-lg">
            {{ bid.user_nickname }}
          </div>
        </div>
        <div v-if="isHighestBid" class="pl-4 text-left text-lg font-bold">
          {{ $t('barometer.the-highest-bid') }}
        </div>
        <span v-if="isCallPrice" class="pl-8 text-left text-lg font-bold">
          {{ $t('barometer.call-price') }}
        </span>
      </div>
      <span v-else>{{ $t('shared-car.confidential') }}</span>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { AuctionState } from '@autobid/ui/types/AuctionState'
import { formatPrice } from '@autobid/ui/utils/formatPrice'
import type { BarometerCar } from '@autobid/ui/types/Car'
import type { Bid } from '@autobid/ui/types/Bid'
import { computed, inject } from 'vue'
import { ENTIRE_DISPLAY_SIZE } from '@autobid/ui/utils/car/getDisplayBids'
import {
  COLORS,
  getBasedOnPrice,
  getColorBasedOnState
} from '@autobid/ui/utils/car/getColor'
import { useAutobidAuth } from '@autobid/nuxt-auth/src/composables/useAutobidAuth'
import { getUsersBids } from '@autobid/ui/utils/car/getUsersBids'

type Props = {
  bid: Bid
  car: BarometerCar
  index: number
  priceAlreadyChanged: boolean
}

const state: AuctionState = inject('state')

const props = defineProps<Props>()

const usersBids = computed(() => getUsersBids(props.car.bids))

const { isAuthed } = useAutobidAuth()
const isHighestBid = computed(
  () => props.bid.amount === usersBids.value[0]?.amount
)

const getCallPriceColor = () => {
  if (state.value.isSold) {
    return COLORS.green.bg
  }

  if (state.value.isUnderAuctionReservation) {
    if (!props.car.price.current && props.car.stage === 'FINISHED')
      return COLORS.blue.bg

    return COLORS.darkOrange.bg
  }

  if (props.car.stage !== 'FINISHED') {
    return COLORS.green.bg
  }

  if (state.value.isNotSold || state.value.isUnderReservation) {
    return COLORS.blue.bg
  }

  return COLORS.green.bg
}

const isCallPrice = computed(() => {
  return (
    props.bid.amount === props.car.price.start &&
    (usersBids.value[0]?.amount ?? 0) < props.car.price.start
  )
})

const isCurrentBid = computed(
  () => props.bid.amount === usersBids.value[0]?.amount
)

const priceWrapperClasses = computed(() => {
  if (!isAuthed.value) return ''

  if (isCallPrice.value) return getCallPriceColor()

  if (isCurrentBid.value) {
    return getColorBasedOnState(props.car, state.value, props.bid.amount, 'bg')
  }

  return ''
})

const notSoldOrReservedAndHasBid = computed(() => {
  return (
    props.car.price.current &&
    !state.value.isUnderAuctionReservation &&
    (state.value.isNotSold || state.value.isUnderReservation)
  )
})

const leftTileClasses = computed(() => {
  if (isCallPrice.value && isAuthed.value) return undefined

  if (priceWrapperClasses.value.length) return priceWrapperClasses.value

  if (props.car.stage !== 'FINISHED') {
    if (!isAuthed.value) {
      // render default colors for not authenticated users - reason: by applying real colors, user can guess a current bid in fact
      const distanceToMiddleOfBarometer =
        Math.floor(ENTIRE_DISPLAY_SIZE / 2) - props.index

      const countedPrice =
        props.car.price.start + distanceToMiddleOfBarometer * props.car.bidStep

      return getBasedOnPrice(props.car, state.value, countedPrice, 'bg')
    }

    return getBasedOnPrice(props.car, state.value, props.bid.amount, 'bg')
  }

  if (notSoldOrReservedAndHasBid.value) {
    return getBasedOnPrice(props.car, state.value, props.bid.amount, 'bg')
  }

  return getColorBasedOnState(props.car, state.value, props.bid.amount, 'bg')
})

const opacity = computed(() => {
  if (isAuthed.value) {
    if (state.value.isPriceDissolution) return 1

    if (
      state.value.isSold &&
      props.car.price.current < props.car.price.start &&
      (state.value.isUnderReservation || state.value.isUnderAuctionReservation)
    ) {
      return 1
    }

    if (
      isCallPrice.value ||
      isCurrentBid.value ||
      (props.car.stage === 'FINISHED' && !notSoldOrReservedAndHasBid.value)
    ) {
      return 1
    }
  }

  const distanceToCallPrice = isAuthed.value
    ? Math.abs(props.bid.amount - props.car.price.start) / props.car.bidStep
    : Math.abs(Math.floor(ENTIRE_DISPLAY_SIZE / 2) - props.index)

  if (distanceToCallPrice < Math.floor(ENTIRE_DISPLAY_SIZE / 2)) {
    return `${distanceToCallPrice * 10}%`
  }

  return 1
})
</script>

<style lang="scss" scoped>
@keyframes zoomInOut {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
}

.current-bid-animation {
  animation: zoomInOut 0.6s ease-in-out;
}
</style>
